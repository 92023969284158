import { getProductReviews } from '@ui/axios/yotpo/getProductReviews';
import { ReviewRatings } from '@ui/components/shared';
import env from '@ui/env';
import { useQuery } from '@tanstack/react-query';
import { ServerProductProps } from '@ui/nextServer';
import { getCanonicalProductId } from '../ProductDetailsReviewsSection/functions';

type ReviewSummaryProps = {
  className?: string;
  product: ServerProductProps['props']['product'];
  hideWhenEmpty?: boolean;
};

export default function ReviewSummary({ className, product, hideWhenEmpty }: ReviewSummaryProps) {
  const { data } = useQuery({
    queryKey: ['productDetailsPageReviews', product.handle],
    queryFn: async () => {
      const productId = await getCanonicalProductId(product);
      return getProductReviews({ productId }).catch(() => null);
    },
  });

  const bottomline = data?.data.response.bottomline || {
    average_score: 0,
    total_review: 0,
  };

  if (!env.PRODUCT_REVIEWS_FEATURE) {
    return null;
  }

  if (hideWhenEmpty && bottomline.total_review === 0) {
    return null;
  }

  return (
    <a
      href="#reviews"
      className={className}
      onClick={() => {
        if (bottomline.total_review > 0) return;
        const selector = '[data-review-form-open]';
        const button = document.querySelector<HTMLElement>(selector);
        button?.click();
      }}
    >
      <ReviewRatings value={bottomline.average_score}>
        {bottomline.total_review <= 0 ? (
          <span>Write a review</span>
        ) : (
          <div className="space-x-2">
            <span>{bottomline.average_score.toFixed(1)}</span>
            <span>({bottomline.total_review === 1 ? '1 review' : `${bottomline.total_review} reviews`})</span>
          </div>
        )}
      </ReviewRatings>
    </a>
  );
}
