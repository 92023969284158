import React from 'react';

const FilledStarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.7894 15.4685C13.7517 15.4685 13.714 15.4601 13.679 15.4432L8.99988 13.1863L4.32073 15.4432C4.23753 15.4835 4.1385 15.4751 4.0633 15.4216C3.98828 15.3682 3.94952 15.2785 3.96285 15.1886L4.70706 10.131L1.07081 6.46808C1.0061 6.40293 0.983519 6.308 1.01232 6.2216C1.04112 6.1352 1.11615 6.07145 1.20753 6.05594L6.34714 5.18691L8.77889 0.665973C8.82209 0.585497 8.90725 0.535156 9.00005 0.535156C9.09286 0.535156 9.17802 0.585497 9.22122 0.665973L11.653 5.18691L16.7926 6.05594C16.884 6.07145 16.9592 6.1352 16.9878 6.2216C17.0164 6.308 16.9938 6.40293 16.9293 6.46808L13.2929 10.131L14.0371 15.1886C14.0502 15.2785 14.0117 15.3683 13.9366 15.4216C13.8931 15.4526 13.8413 15.4685 13.7894 15.4685Z"
      fill="currentColor"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);

export default FilledStarIcon;
