import React from 'react';
import { ShareIcon, ThumbDownIcon, ThumbUpIcon, VerifiedBadgeIcon } from '@ui/components/core';
import { ReviewRatings } from '@ui/components/shared';
import { UnstyledButton } from '@mantine/core';
import Image from 'next/image';
import voteProductReview from '@ui/axios/yotpo/voteProductReview';
import env from '@ui/env';
import type { ProductReview, ProductReviewImage } from '@ui/axios/yotpo/getProductReviews';
import cn from '@ui/utils/cn';
import ProductFitChart from './ProductFitChart';
import { normalizeHeight, normalizeSize, unescapeHtmlEntities } from './functions';

type Vote = 'up' | 'down' | null;

type Props = {
  className?: string;
  review: ProductReview;
  onImageClick?: (image: ProductReviewImage) => void;
};

export default function ProductReviewCard({ className, review, onImageClick }: Props) {
  const fit = Object.values(review.custom_fields || {}).find((cf) => cf.title === 'Fit');
  const size = Object.values(review.custom_fields || {}).find((cf) => cf.title === 'Size');
  const height = Object.values(review.custom_fields || {}).find((cf) => cf.title === 'Height');

  const [vote, setVote] = React.useState<Vote>(null);
  const [upvotes, setUpvotes] = React.useState(review.votes_up);
  const [downvotes, setDownvotes] = React.useState(review.votes_down);
  const [isVoting, setIsVoting] = React.useState(false);

  async function handleVote(previousVote: Vote, newVote: Vote) {
    if (previousVote === newVote) return;

    setIsVoting(true);

    try {
      if (previousVote) {
        await voteProductReview({
          reviewId: review.id.toString(),
          vote: previousVote,
          undo: true,
        });
      }

      if (newVote && previousVote !== newVote) {
        await voteProductReview({
          reviewId: review.id.toString(),
          vote: newVote,
        });
      }

      if (previousVote === 'up') {
        setUpvotes((previousUpvote) => previousUpvote - 1);
      } else if (previousVote === 'down') {
        setDownvotes((previousDownvote) => previousDownvote - 1);
      }

      if (newVote === 'up') {
        setUpvotes((previousUpvote) => previousUpvote + 1);
      } else if (newVote === 'down') {
        setDownvotes((previousDownvote) => previousDownvote + 1);
      }

      setVote(newVote);
    } catch {
      //
    }

    setIsVoting(false);
  }

  const UpvoteButton = ({ className }: { className?: string }) => (
    <UnstyledButton
      onClick={() => handleVote(vote, 'up')}
      className={cn('flex items-center space-x-1 disabled:opacity-20 transition-opacity', className)}
      disabled={isVoting}
    >
      <ThumbUpIcon width={18} height={17} />
      <span className={cn('text-[15px]', vote === 'up' && 'font-black')}>{upvotes}</span>
    </UnstyledButton>
  );

  const DownvoteButton = ({ className }: { className?: string }) => (
    <UnstyledButton
      onClick={() => handleVote(vote, 'down')}
      className={cn('flex items-center space-x-1 disabled:opacity-20 transition-opacity', className)}
      disabled={isVoting}
    >
      <ThumbDownIcon width={18} height={17} />
      <span className={cn('text-[15px]', vote === 'down' && 'font-black')}>{downvotes}</span>
    </UnstyledButton>
  );

  const ShareButton = ({ className }: { className?: string }) => (
    <UnstyledButton className={cn('flex items-center', className)}>
      <ShareIcon width={15} height={20} />
    </UnstyledButton>
  );

  return (
    <div className={cn('flex flex-col justify-between', className)}>
      <div className="space-y-6">
        <div className="flex items-baseline justify-between">
          <div className="space-y-3">
            <div className="space-y-1.5">
              <div className="text-xs font-semibold uppercase tracking-[0.004em]">{review.user.display_name}</div>
              {review.verified_buyer && (
                <div className="flex items-center">
                  <div className="text-xs tracking-[0.004em]">Verified Buyer</div>
                  <VerifiedBadgeIcon className="size-3.5 ml-1.5" />
                </div>
              )}
            </div>
            <ReviewRatings value={review.score} />
          </div>
          <div>
            <time className="text-xs tracking-[0.004em]" dateTime={review.created_at}>
              {new Date(review.created_at).toLocaleDateString()}
            </time>
          </div>
        </div>
        <div>
          <div className="text-[14px] font-bold tracking-[0.6px] uppercase">{unescapeHtmlEntities(review.title)}</div>
          <div className="text-[14px] tracking-[0.6px] leading-relaxed mt-2">{unescapeHtmlEntities(review.content)}</div>
          {(height || size) && (
            <div className="mt-2 flex space-x-4">
              {size && (
                <div className="flex space-x-2 text-xs tracking-[0.4px]">
                  <span className="font-bold">Size:</span>
                  <span>{normalizeSize(size.value)}</span>
                </div>
              )}

              {height && (
                <div className="flex space-x-2 text-xs tracking-[0.4px]">
                  <span className="font-bold">Height:</span>
                  <span>{normalizeHeight(height.value)}</span>
                </div>
              )}
            </div>
          )}
        </div>
        {review.images_data?.length && (
          <div className="grid grid-cols-3 md:grid-cols-4 gap-2">
            {review.images_data.map((image) => (
              <div key={image.id} className="relative pb-[100%]">
                <Image
                  className="w-full h-full absolute cursor-pointer"
                  width={150}
                  height={150}
                  src={image.thumb_url}
                  alt=""
                  onClick={() => onImageClick?.(image)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mt-6 space-y-4">
        {fit && <ProductFitChart className="w-[220px]" value={Number(fit.value)} />}
        {env.PRODUCT_REVIEWS_VOTE_FEATURE && (
          <div>
            <div className="hidden md:flex items-center justify-between">
              <div className="text-[13px] font-normal">Was this review helpful?</div>
              <div className="flex items-center">
                <UpvoteButton />
                <DownvoteButton className="ml-4" />
                <ShareButton className="ml-8" />
              </div>
            </div>
            <div className="flex md:hidden items-end justify-between">
              <ShareButton />
              <div className="flex">
                <UpvoteButton />
                <DownvoteButton className="ml-4" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
