import React from 'react';

const ProfileCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 73 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M62 22L49.8497 40L43 31.9455" stroke="black" strokeWidth="1.2" strokeMiterlimit="10" />
    <path
      d="M24.3729 30.7388C27.8907 30.7388 30.7423 27.8871 30.7423 24.3694C30.7423 20.8517 27.8907 18 24.3729 18C20.8552 18 18.0035 20.8517 18.0035 24.3694C18.0035 27.8871 20.8552 30.7388 24.3729 30.7388Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M24.3729 30.9604C33.6283 30.9604 36.5817 41.3914 35.5459 43.3909C35.1701 44.1136 34.3944 44.21 33.7777 44.21H14.9874C14.3467 44.21 13.5758 44.1136 13.1951 43.3909C12.1641 41.3914 15.1224 30.9604 24.3729 30.9604Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M59.6414 1H13.3586C6.53309 1 1 6.52096 1 13.3314V48.6686C1 55.479 6.53309 61 13.3586 61H59.6414C66.4669 61 72 55.479 72 48.6686V13.3314C72 6.52096 66.4669 1 59.6414 1Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ProfileCheckIcon;
