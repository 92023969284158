import createProductReview from '@ui/axios/yotpo/createProductReview';
import { FormHelperText, FormInput, FormLabel, FormRadio, FormTextarea } from '@ui/components/core';
import { Button } from '@ui/components/shared';
import { ReviewRatings } from '@ui/components/shared';
import { Radio } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import env from '@ui/env';
import axios from 'axios';
import cn from '@ui/utils/cn';
import { HeightFilterMappings } from '@ui/axios/yotpo/getFilteredProductReviews';
import { getCanonicalProductId } from './functions';

type ProductReviewFormProps = {
  className?: string;
  onLoading?: () => void;
  onSuccess?: () => void;
  onComplete?: () => void;
  product: {
    id: string;
    handle: string;
    title: string;
    url: string;
    sizes: string[];
  };
};

export default function ProductReviewForm({
  className,
  onLoading,
  onSuccess,
  onComplete,
  product,
}: ProductReviewFormProps) {
  const form = useForm({
    initialValues: {
      reviewerName: '',
      reviewerEmail: '',
      reviewContent: '',
      reviewTitle: '',
      reviewScore: '',
      customFieldSize: '',
      customFieldHeight: '',
      customFieldFit: '',
    },
    validate: {
      reviewerName: (value) => (value.trim().length ? null : 'Name is required.'),
      reviewerEmail: (value) => (value.trim().length ? null : 'Email is required.'),
      reviewContent: (value) => (value.trim().length ? null : 'Content is required.'),
      reviewTitle: (value) => (value.trim().length ? null : 'Title is required.'),
      reviewScore: (value) => (value.trim().length ? null : 'Score is required.'),
      customFieldSize: (value) => (value.trim().length ? null : 'Size is required.'),
      customFieldHeight: (value) => (value.trim().length ? null : 'Height is required.'),
      customFieldFit: (value) => (value.trim().length ? null : 'Fit is required.'),
    },
    validateInputOnBlur: false,
    clearInputErrorOnChange: true,
  });

  return (
    <form
      noValidate
      className={className}
      id="create-review-form"
      onSubmit={form.onSubmit(async (values, event) => {
        event.preventDefault();

        onLoading?.();

        try {
          if (!env.PRODUCT_REVIEWS_CREATE_FEATURE) {
            throw new Error('Review creation is disabled');
          }

          const productId = await getCanonicalProductId(product);

          await createProductReview({
            productId: productId,
            productTitle: product.title,
            productUrl: product.url,
            reviewerName: values.reviewerName,
            reviewerEmail: values.reviewerEmail,
            reviewContent: values.reviewContent,
            reviewTitle: values.reviewTitle,
            reviewScore: Number(values.reviewScore),
            customFieldSize: values.customFieldSize,
            customFieldHeight: values.customFieldHeight,
            customFieldFit: values.customFieldFit,
          });

          onSuccess?.();
        } catch (err) {
          notifications.show({
            autoClose: 3000,
            withCloseButton: true,
            variant: 'danger',
            message: 'An error occurred when creating your review.',
          });
        } finally {
          onComplete?.();
        }
      })}
    >
      <div className="space-y-4">
        <div>
          <FormLabel required>Score</FormLabel>

          <div className="mt-2">
            <ReviewRatings
              className="[&_[data-slot=icon]]:size-5"
              value={Number(form.values.reviewScore)}
              onChange={(newScore) => form.setFieldValue('reviewScore', newScore.toString())}
            />
          </div>

          {form.errors.reviewScore && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewScore}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="reviewTitle" required>
            Title
          </FormLabel>

          <FormInput
            {...form.getInputProps('reviewTitle', { withError: false })}
            invalid={Boolean(form.errors.reviewTitle)}
            autoComplete="off"
            id="reviewTitle"
            name="reviewTitle"
            type="text"
            required
          />

          {form.errors.reviewTitle && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewTitle}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="reviewContent" required>
            Review
          </FormLabel>

          <FormTextarea
            {...form.getInputProps('reviewContent', { withError: false })}
            invalid={Boolean(form.errors.reviewContent)}
            autoComplete="off"
            id="reviewContent"
            name="reviewContent"
            required
            rows={4}
          />

          {form.errors.reviewContent && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewContent}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="customFieldSize" required>
            What size did you purchase?
          </FormLabel>

          <div className="flex gap-4 mt-2">
            {product.sizes.map((size) => (
              <Button
                key={size}
                onClick={() => form.setFieldValue('customFieldSize', size)}
                data-checked={form.values.customFieldSize === size || undefined}
                className={cn('size-11 bg-transparent font-normal border p-0', size === 'One Size' && 'w-auto px-2')}
                variant="outline"
                type="button"
              >
                {size}
              </Button>
            ))}
          </div>

          {form.errors.customFieldSize && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.customFieldSize}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel required>How does it fit?</FormLabel>

          <Radio.Group
            value={form.values.customFieldFit}
            onChange={(value) => form.setFieldValue('customFieldFit', value)}
            name="reviewFit"
            size="xs"
          >
            <div className="space-y-4 mt-2">
              <FormRadio
                value="1"
                label={
                  <div className="flex items-center justify-between md:justify-start space-x-8">
                    <div className="text-[13px] w-[132px]">Smaller than expected</div>
                    <div className="flex space-x-0.5">
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black bg-[#DE4D58]"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                    </div>
                  </div>
                }
              />
              <FormRadio
                value="2"
                label={
                  <div className="flex items-center justify-between md:justify-start space-x-8">
                    <div className="text-[13px] w-[132px]">True to size</div>
                    <div className="flex space-x-0.5">
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black bg-[#46B67A]"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                    </div>
                  </div>
                }
              />
              <FormRadio
                value="3"
                label={
                  <div className="flex items-center justify-between md:justify-start space-x-8">
                    <div className="text-[13px] w-[132px]">Larger than expected</div>
                    <div className="flex space-x-0.5">
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black"></div>
                      <div className="w-[18px] h-[6px] border-[0.5px] border-black bg-[#DE4D58]"></div>
                    </div>
                  </div>
                }
              />
            </div>
          </Radio.Group>

          {form.errors.customFieldFit && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.customFieldFit}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel required>How tall are you?</FormLabel>

          <Radio.Group
            value={form.values.customFieldHeight}
            onChange={(value) => form.setFieldValue('customFieldHeight', value)}
            name="reviewHeight"
            size="xs"
          >
            <div className="space-y-4 mt-2">
              {Object.keys(HeightFilterMappings).map((label) => (
                <FormRadio
                  key={label}
                  value={HeightFilterMappings[label][0]}
                  label={label}
                  classNames={{ labelWrapper: 'text-[13px]' }}
                />
              ))}
            </div>
          </Radio.Group>

          {form.errors.customFieldHeight && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.customFieldHeight}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="reviewerName" required>
            Your name (will be visible to the public)
          </FormLabel>

          <FormInput
            {...form.getInputProps('reviewerName', { withError: false })}
            invalid={Boolean(form.errors.reviewerName)}
            autoComplete="name"
            id="reviewerName"
            name="reviewerName"
            type="text"
            required
          />

          {form.errors.reviewerName && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewerName}
            </FormHelperText>
          )}
        </div>

        <div>
          <FormLabel htmlFor="reviewerEmail" required>
            Your email (for validation purposes only)
          </FormLabel>

          <FormInput
            {...form.getInputProps('reviewerEmail', { withError: false })}
            invalid={Boolean(form.errors.reviewerEmail)}
            autoComplete="email"
            id="reviewerEmail"
            name="reviewerEmail"
            type="email"
            required
          />

          {form.errors.reviewerEmail && (
            <FormHelperText className="mt-1" invalid>
              {form.errors.reviewerEmail}
            </FormHelperText>
          )}
        </div>
      </div>
    </form>
  );
}
