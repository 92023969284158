import React from 'react';
import useModalStore from '@ui/store/modalStore';
import useAddToCart from '@ui/hooks/useAddToCart';
import { AppContext } from '@ui/context/context';
import { Button, AddedToCart } from '@ui/components/shared';
import { ServerProductProps } from '@ui/nextServer';

type AddToCartButtonProps = {
  selectedVariant: ServerProductProps['props']['product']['variants'][number] | undefined;
  selectedSSVariantSku: string | undefined;
  label: string | React.ReactNode;
  onSuccess?: () => void;
};

const AddToCartButton = ({ selectedVariant, selectedSSVariantSku, label, onSuccess }: AddToCartButtonProps) => {
  const { state } = React.useContext(AppContext);
  const [showPleaseSelectASizeButton, setShowPleaseSelectASizeButton] = React.useState(false);
  const sizeIsSelected = React.useMemo(
    () => !!(selectedVariant || selectedSSVariantSku),
    [selectedVariant, selectedSSVariantSku],
  );

  React.useEffect(() => {
    if (sizeIsSelected) {
      setShowPleaseSelectASizeButton(false);
    }
  }, [sizeIsSelected]);

  const openModal = useModalStore((state) => state.openModal);
  const [showAddedToBag, setShowAddedToBag] = React.useState(false);

  const runAddedToBagAnimation = () => {
    setShowAddedToBag(true);
    setTimeout(() => setShowAddedToBag(false), 3000);
    openModal('cart');
    onSuccess?.();
  };

  const { addToCart, isLoading } = useAddToCart({
    onSuccess: runAddedToBagAnimation,
  });

  return (
    <div className="relative overflow-hidden">
      {showPleaseSelectASizeButton ? (
        <Button className="w-full h-12 bg-[#FAE6EE]" variant="outline">
          Please select a size
        </Button>
      ) : (
        <Button
          className="w-full h-12"
          loading={isLoading}
          onClick={() => {
            if (!selectedVariant) {
              setShowPleaseSelectASizeButton(true);
              return;
            }
            addToCart({
              productVariantId: selectedVariant.id,
              intellisuggestData: state.ssViewedProduct.intellisuggestData,
              intellisuggestSignature: state.ssViewedProduct.intellisuggestSignature,
            });
          }}
        >
          {label}
        </Button>
      )}

      <AddedToCart show={showAddedToBag} fill />
    </div>
  );
};

AddToCartButton.displayName = 'AddToCartButton';

export default AddToCartButton;
