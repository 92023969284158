import React, { useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { useCookie, setCookie } from '@ui/hooks/useCookie';
import { Anchor, Breadcrumbs, Container } from '@ui/components/core';
import { ProductDetailsSection } from '@ui/components/features';
import { useInView } from 'react-intersection-observer';
import useCartStore from '@ui/store/cartStore';
import useRecentlyViewedStore, { useValidProducts } from '@ui/store/recentlyViewedStore';
import cn from '@ui/utils/cn';
import { BreadcrumbLink, BreadcrumbMapping } from '@ui/types/mappings/BreadcrumbMappings.type';
import { orderBy } from 'lodash';
import Link from 'next/link';
import { useQuery } from '@tanstack/react-query';
import { GetProduct } from '@client-shopify/gql/storefront/api/queries';
import useRevalidate from '@ui/hooks/useRevalidate';
import { ProductProp } from '@client-shopify/gql/storefront/api/queries/GetProduct';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import useYotpoDataLayer from '@ui/hooks/useYotpoDataLayer';
import useProductScarabQueue from '@ui/hooks/useProductScarabQueue';

const returnProductHandle = (router: NextRouter) => {
  const { query } = router;
  switch (true) {
    case typeof query.product === 'string':
      return query.product as string;
    case Array.isArray(query.collections):
      const productHandle = query.collections !== undefined ? query.collections[query.collections.length - 1] : '';
      return productHandle;
    default:
      return '';
  }
};

const returnCollection = (router: NextRouter) => {
  const { query } = router;
  switch (true) {
    case Array.isArray(query.collections):
      return query.collections[0] as string;
    default:
      return '';
  }
};

const ProductPage = ({ product: productProp }: { product: ProductProp }) => {
  useProductScarabQueue({ productId: productProp.id });
  const { yotpoProductView } = useYotpoDataLayer();
  const { elevarViewItemEvent } = useElevarDataLayer();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const { data: product } = useQuery({
    initialData: productProp,
    staleTime: 0,
    queryKey: ['productPage', productProp.handle],
    queryFn: async () => {
      const res = await GetProduct({ handle: productProp.handle });
      return res?.product;
    },
  });

  useRevalidate({
    cachedData: productProp,
    actualData: product,
  });

  const recentlyViewed = useValidProducts();
  const cartId = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const ssUserIdCookie = useCookie('ssUserId');
  const ssSessionIdNamespaceCookie = useCookie('ssSessionIdNamespace');
  const pageLoadIdCookie = useCookie('pageLoadId');
  const shopperCookie = useCookie('shopper');
  const cartCookie = useCookie('cart');
  const lastViewedCookie = useCookie('lastViewed');
  const { setRecentlyViewed } = useRecentlyViewedStore();

  const router = useRouter();
  const handle = returnProductHandle(router);
  const collection = returnCollection(router);
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbLink[]>([]);

  const { data: getCartQueryResults } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartId || '',
  });

  useEffect(() => {
    if (!product) return;
    const ensureProductBelongsToCollection = (collectionPath: string) => {
      const [, collection, ...filters] = collectionPath.split('/').filter(Boolean);
      return ![`category_${collection}`, ...filters].some((tag) => !product.tags.includes(tag));
    };

    const collectionPageFromSession = sessionStorage.getItem('lastVisitedCollectionPage');

    if (
      collectionPageFromSession &&
      ensureProductBelongsToCollection(collectionPageFromSession) &&
      BreadcrumbMapping[collectionPageFromSession]
    ) {
      setBreadcrumbs(BreadcrumbMapping[collectionPageFromSession]);
      return;
    }

    const collectionPageFromRouter = router.query.collections && `/collections/${router.query.collections[0]}`;

    if (collectionPageFromRouter && BreadcrumbMapping[collectionPageFromRouter]) {
      setBreadcrumbs(BreadcrumbMapping[collectionPageFromRouter]);
      return;
    }

    const determineSpecifity = (collectionPath: string) => {
      if (collectionPath.startsWith('/collections/new')) return -Infinity;
      if (collectionPath.startsWith('/collections/back-in-stock')) return -Infinity;
      if (collectionPath.startsWith('/collections/clothing')) return -Infinity;
      return collectionPath.split('/').length;
    };

    const sortedBreadcrumbs = orderBy(Object.keys(BreadcrumbMapping), determineSpecifity, 'desc');
    const collectionPageFromTags = sortedBreadcrumbs.find((key) => ensureProductBelongsToCollection(key));

    if (collectionPageFromTags) {
      setBreadcrumbs(BreadcrumbMapping[collectionPageFromTags]);
    } else {
      setBreadcrumbs([]);
    }
  }, [product, router]);

  React.useEffect(() => {
    if (product?.id) {
      yotpoProductView(product?.id);
    }
    elevarViewItemEvent(
      {
        image: product?.images[0]?.url ?? '',
        brand: product?.vendor ?? '',
        category: 'category',
        id: product?.variants[0].sku ?? '',
        compare_at_price: product?.compareAtPrice?.amount ? String(product?.compareAtPrice?.amount) : '',
        list: collection,
        name: product?.title ?? '',
        position: 1,
        price: product?.compareAtPrice?.amount ? String(product?.compareAtPrice?.amount) : '',
        product_id: product?.id.split('/').at(-1) ?? '',
        quantity: null,
        variant: product?.variants[0].sku.split('-1').at(-1) ?? '',
        variant_id: product?.variants[0]?.id.split('/').at(-1) ?? '',
      },
      router.asPath,
    );
  }, [
    elevarViewItemEvent,
    router.asPath,
    yotpoProductView,
    product?.variants,
    product?.vendor,
    product?.images,
    collection,
    product?.compareAtPrice?.amount,
    product?.id,
    product?.price.amount,
    product?.title,
  ]);

  React.useEffect(() => {
    if (!inView) return;
    setRecentlyViewed(product?.id as string, {
      cartCookie: cartCookie,
      lastViewedCookie: lastViewedCookie,
      pageLoadIdCookie: pageLoadIdCookie,
      product: handle,
      shopperCookie: shopperCookie,
      ssSessionIdNamespaceCookie: ssSessionIdNamespaceCookie,
      ssUserIdCookie: ssUserIdCookie,
    });
  }, [
    cartCookie,
    product?.id,
    lastViewedCookie,
    pageLoadIdCookie,
    handle,
    setRecentlyViewed,
    shopperCookie,
    ssSessionIdNamespaceCookie,
    ssUserIdCookie,
    inView,
  ]);

  React.useEffect(() => {
    let lastViewedString = '';
    recentlyViewed?.forEach((recentlyViewedProduct) => {
      lastViewedString += `${recentlyViewedProduct.ssSku},`;
    });

    setCookie('lastViewed', lastViewedString.slice(0, -1));
  }, [recentlyViewed, collection]);

  React.useMemo(() => {
    if (!cartUpdatedKey) return;
    if (getCartQueryResults?.cart?.cartLines && getCartQueryResults.cart.cartLines.length) {
      const cartString = getCartQueryResults.cart.cartLines.map((cartProduct) => cartProduct.sku).join(',');

      setCookie('cart', cartString);
    }
  }, [cartUpdatedKey, getCartQueryResults]);

  return (
    <div className="min-h-screen">
      {product && (
        <div className={cn('transition-opacity duration-300 opacity-100')}>
          <Container className="px-4 py-2.5 md:px-8 max-sm:hidden">
            <Breadcrumbs
              classNames={{
                breadcrumb: 'text-[10px] text-[#727272] tracking-[1.5px] capitalize',
                separator: 'text-[10px] text-[#727272] mx-1',
              }}
            >
              {breadcrumbs.map(({ label, href }) => (
                <Anchor key={label} href={href} component={Link}>
                  {label}
                </Anchor>
              ))}
            </Breadcrumbs>
          </Container>
          <Container className="px-0 md:px-8 pt-0 pb-12 md:pb-4" ref={ref}>
            <ProductDetailsSection product={product} />
          </Container>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
