import React from 'react';
import { Radio as RadioPrimitive, RadioProps as RadioPrimitiveProps } from '@mantine/core';
import cn from '@ui/utils/cn';

export type RadioProps = RadioPrimitiveProps;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ classNames, ...props }, ref) => {
  return (
    <RadioPrimitive
      {...props}
      ref={ref}
      classNames={{
        radio: cn('border border-solid !border-black checked:bg-white', classNames?.radio),
        inner: cn('flex items-center justify-center', classNames?.inner),
        icon: cn('text-[#EA98B8] w-2.5 h-2.5 absolute top-auto left-auto', classNames?.icon),
        labelWrapper: cn('flex-1', classNames?.labelWrapper),
      }}
    />
  );
});

Radio.displayName = 'Radio';

export default Radio;
