import axios from 'axios';

type VoteProductReviewArgs = {
  reviewId: string;
  vote: 'up' | 'down';
  undo?: boolean;
};

const voteProductReview = async ({ reviewId, vote, undo }: VoteProductReviewArgs) => {
  const { data } = await axios.request({
    method: 'POST',
    url: undo
      ? `https://api.yotpo.com/reviews/${reviewId}/vote/${vote}/true`
      : `https://api.yotpo.com/reviews/${reviewId}/vote/${vote}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return { data };
};

export default voteProductReview;
