import React from 'react';

const GiftStringIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M51.376 60.83H12.4228C6.0955 60.83 1 56.0819 1 50.2179V14.4862C1 7.03248 6.0955 1 12.3912 1H51.3444C57.6611 1 62.7355 7.03248 62.7355 14.4862V50.2179C62.7671 56.0819 57.6716 60.83 51.376 60.83Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path d="M31.8783 60.83V1" stroke="black" strokeWidth="1.2" strokeMiterlimit="10" />
    <path
      d="M31.8783 31.3415C31.8783 31.3415 39.9742 10.412 45.9962 19.7608C52.0181 29.1096 31.8783 31.3415 31.8783 31.3415Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M31.8783 31.3415C31.8783 31.3415 23.7823 10.412 17.7709 19.7608C11.7595 29.1096 31.8783 31.3415 31.8783 31.3415Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path d="M1 32.3416H62.7671" stroke="black" strokeWidth="1.2" strokeMiterlimit="10" />
    <path
      d="M31.6783 31.7836C28.1409 36.7738 19.1291 42.4062 16.2339 42.4062"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M32.1415 31.7836C35.6894 36.7738 44.6907 42.4062 47.5859 42.4062"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default GiftStringIcon;
