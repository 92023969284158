import React from 'react';
import BagHeartIcon from '@ui/components/core/Icons/WearNowPayLater/BagHeartIcon';
import ProfileCheckIcon from '@ui/components/core/Icons/WearNowPayLater/ProfileCheckIcon';
import GiftStringIcon from '@ui/components/core/Icons/WearNowPayLater/GiftStringIcon';
import PieIcon from '@ui/components/core/Icons/WearNowPayLater/PieIcon';
import { AfterpayIcon, KlarnaIcon, Dialog } from '@ui/components/core';
import { useStaticContent } from '@ui/providers/static-content-provider';
import Link from 'next/link';
import { upperFirst } from 'lodash';
import env from '@ui/env';

type DialogProps = {
  open: boolean;
  onClose: () => void;
};

const WearNowPayLaterDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const channels = useStaticContent('WearNowPayLater.Channels');

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Content className="max-w-sm p-6 grid rounded-md border border-black gap-4">
        <Dialog.Header>
          <Dialog.Title>Buy Now / Pay Later</Dialog.Title>
        </Dialog.Header>
        <hr className="border-black border-t-[0.6px]" />
        <p className="text-xs leading-loose tracking-[0.04em]">
          Shop with {channels.map(upperFirst).join(' or ')} to pay for your order in four simple payments with no added
          interest or fees.
        </p>
        <h3 className="text-sm font-semibold uppercase tracking-[0.04em]">How it works</h3>
        <div className="flex items-center space-x-4">
          <BagHeartIcon className="w-10 h-auto shrink-0" />
          <div className="flex-1">
            <div className="text-xs leading-loose tracking-[0.04em]">1. Bag up your faves and head to checkout.</div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <ProfileCheckIcon className="w-10 h-auto shrink-0" />
          <div className="flex-1">
            <div className="text-xs leading-loose tracking-[0.04em]">
              2. Submit your details with {channels.map(upperFirst).join(' or ')}. Approval is instant!
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <GiftStringIcon className="w-10 h-auto shrink-0" />
          <div className="flex-1">
            <div className="text-xs leading-loose tracking-[0.04em]">
              3. Hello Molly will send your order out like a regular purchase.
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <PieIcon className="w-10 h-auto shrink-0" />
          <div className="flex-1">
            <div className="text-xs leading-loose tracking-[0.04em]">
              4. Your purchase will be split into 4 payments payable every 2 weeks.
            </div>
          </div>
        </div>
        <div>
          <p className="text-xs leading-loose tracking-[0.04em]">All you need to get started:</p>
          <ul className="text-xs leading-loose tracking-[0.04em] list-disc pl-4">
            <li>A valid {env.NEXT_PUBLIC_REGION} credit or debit card</li>
            <li>Be over 18 years old and living in {env.NEXT_PUBLIC_REGION}</li>
          </ul>
        </div>
        <Dialog.Footer>
          {channels.includes('afterpay') && (
            <Link
              href="/pages/wear-now-pay-later#afterpay"
              className="inline-flex items-center justify-center h-10 bg-[#b2fce4] px-4"
            >
              <AfterpayIcon className="w-[92px] h-[19px]" />
            </Link>
          )}
          {channels.includes('klarna') && (
            <Link
              href="/pages/wear-now-pay-later#klarna"
              className="inline-flex items-center justify-center h-10 bg-[#ffb3c7] px-4"
            >
              <KlarnaIcon className="w-[61px] h-[15px]" />
            </Link>
          )}
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default WearNowPayLaterDialog;
