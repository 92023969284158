import React from 'react';

import SSIntelliSuggestProductView from '@ui/axios/searchSpring/intellisuggestProductView';
import ProductDetailsRecentlyViewedSection from '../ProductDetailsRecentlyViewedSection/ProductDetailsRecentlyViewedSection';
import ProductDetailsCarousel from '@ui/components/features/product/ProductDetailsCarousel/ProductDetailsCarousel';
import ProductDetailsReviewsSection from '../ProductDetailsReviewsSection/ProductDetailsReviewsSection';
import ProductDetailsRightSection from '../ProductDetailsRightSection/ProductDetailsRightSection';
import ProductDetailsLeftSection from '../ProductDetailsLeftSection/ProductDetailsLeftSection';
import { AppContext } from '@ui/context/context';
import { useInView } from 'react-intersection-observer';
import env from '@ui/env';
import { useValidProducts } from '@ui/store/recentlyViewedStore';
import ProductDetailsStyleWithCarousel from '../ProductDetailsStyleWithCarousel/ProductStyleWithCarousel';
import { getProductServerSidePropsHelper } from '@ui/nextServer';

export default function ProductDetailsSection({
  product,
}: {
  product: Awaited<ReturnType<typeof getProductServerSidePropsHelper>>['props']['product'];
}): React.ReactElement {
  const validProducts = useValidProducts();
  const { state } = React.useContext(AppContext);
  const { ref: belowTheFoldAnchor, inView: belowTheFold } = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-10 md:gap-[42px]">
        <div className="col-span-1 md:col-span-7">
          <ProductDetailsLeftSection product={product} />
        </div>
        <div className="col-span-1 md:col-span-3">
          <ProductDetailsRightSection product={product} />
        </div>
      </div>

      <div ref={belowTheFoldAnchor} />

      <div className="px-4 md:px-0 divide-y-[0.6px] divide-black [&>div]:py-8 md:[&>div]:py-[52px] md:mt-[52px]">
        <ProductDetailsStyleWithCarousel className="md:hidden" product={product} controls />
        {env.PRODUCT_REVIEWS_FEATURE && <ProductDetailsReviewsSection product={product} />}
        {belowTheFold && env.PDP_DONT_MISS_OUT_FEATURE && (
          <ProductDetailsCarousel product={product} title="Don't Miss Out" tags="home-page-new" controls />
        )}
        {belowTheFold && <ProductDetailsCarousel product={product} title="You May Also Like" tags="similar" controls />}
        {belowTheFold && <ProductDetailsRecentlyViewedSection products={validProducts || []} />}
      </div>

      <SSIntelliSuggestProductView productSku={state.ssViewedProduct.sku} />
    </>
  );
}
