import React from 'react';
import useStyles from './useStyles';

const OutOfStock = () => {
  const styles = useStyles();

  return (
    <div className={styles.classes.root}>
      <span className={styles.classes.label}>Out of Stock !</span>
    </div>
  );
};

export default OutOfStock;
