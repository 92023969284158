import React from 'react';

const ThumbDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.5042 10.1182L17.3439 10.1182C18.3557 10.1182 19.1836 9.2903 19.1836 8.27846L19.1836 1.83948C19.1836 0.827641 18.3557 -0.000227856 17.3439 -0.000227767L15.5042 -0.000227606C14.4923 -0.000227518 13.6645 0.827641 13.6645 1.83948L13.6645 8.27846C13.6645 9.2903 14.4923 10.1182 15.5042 10.1182ZM15.5042 1.83948L17.3439 1.83948L17.3439 8.27846L15.5042 8.27846L15.5042 1.83948Z"
      fill="black"
    />
    <path
      d="M3.95953 10.1191L5.52329 10.1191L5.52329 10.1651C5.20134 11.5449 4.60343 14.1665 5.66126 15.7762C6.0752 16.4201 6.76509 16.834 7.59296 16.972C8.09888 17.064 8.65079 16.926 9.06472 16.6041C9.47866 16.3281 9.70862 15.9142 9.80061 15.4543C10.1226 13.3846 11.3184 10.993 12.0542 9.5672C12.5602 8.60135 12.7441 8.23341 12.7441 7.86547L12.7441 1.42649C12.7441 1.05855 12.6062 0.736605 12.3762 0.506641C11.8703 0.000721054 11.1344 0.000721119 10.5365 0.000721171L10.4905 0.000721175L4.00553 0.000721742C2.11983 0.000721907 0.83203 0.966567 0.83203 2.34635L0.83203 6.94562C0.786038 8.46338 2.44178 10.1191 3.95953 10.1191ZM2.62575 2.34635C2.62575 2.11639 3.31564 1.84043 3.95953 1.84043L10.5365 1.84043C10.6285 1.84043 10.7665 1.84043 10.9044 1.84043L10.9044 7.77349C10.8124 7.95746 10.6285 8.37139 10.4445 8.73933C9.66263 10.2571 8.37483 12.8327 8.05288 15.0863C8.05288 15.1323 7.9609 15.1323 7.91491 15.1323C7.50097 15.0403 7.317 14.9024 7.22502 14.7184C6.58112 13.7985 7.13303 11.5449 7.36299 10.579L7.40899 10.3031C7.40899 10.2111 7.45498 10.1191 7.50097 10.0271C7.59296 9.79717 7.82292 9.29124 7.50097 8.78533C7.27101 8.46338 6.94906 8.27941 6.44314 8.27941L3.95953 8.27941C3.45362 8.27941 2.62575 7.45154 2.62575 6.89963L2.62575 2.34635Z"
      fill="black"
    />
  </svg>
);

export default ThumbDownIcon;
