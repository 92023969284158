import { ServerProductProps } from '@ui/nextServer/';
import { personalizedRecommendations, search } from '@ui/axios/searchSpring';
import { ApiResult } from '@ui/axios/searchSpring/search';
import React from 'react';
import ProductDetailsCarousel from '../ProductDetailsCarousel/ProductDetailsCarousel';
import { CarouselProps } from '@mantine/carousel';
import { ProductCardCarousel } from '@ui/components/shared';
import cn from '@ui/utils/cn';
import { useQuery } from '@tanstack/react-query';
import { shuffle } from 'lodash';
import { recommendationProductsToSearchProducts } from '@ui/helpers.export';
import env from '@ui/env';
import { GetProductsPricing } from '@client-shopify/gql/storefront/api/queries';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import { SearchProduct } from '@ui/helpers/recommendationProductsToSearchProducts';

const ProductDetailsStyleWithCarousel: React.FC<{
  className?: string;
  classNames?: CarouselProps['classNames'];
  controls: boolean;
  limits?: number;
  draggable?: boolean;
  product: ServerProductProps['props']['product'];
}> = ({ product, className, classNames, controls, draggable, limits = 4 }) => {
  const productIdentifier = product.variants?.[0]?.sku || product.id.split('/').pop();
  const styleWithHandles = JSON.parse(product.styleWith?.value || '[]') as Array<string>;
  const [isReady, setReady] = React.useState(styleWithHandles.length === 0);

  const { data: styleWithRecommendations, isLoading } = useQuery({
    enabled: styleWithHandles.length > 0,
    queryKey: ['style-with', product.id],
    queryFn: async () => {
      let resultsFromMetafield = (
        await Promise.all(
          styleWithHandles.map((handle) =>
            search({
              ssUserId: '',
              ssSessionIdNamespace: '',
              pageLoadId: '',
              shopper: '',
              cart: '',
              lastViewed: '',
              query: handle,
              resultsPerPage: 1,
            })
              .then((res) => res.data.results?.[0] || null)
              .catch(() => null),
          ),
        )
      ).filter((result) => {
        return result !== null && parseInt(result.ss_instock_pct) > 0; // drop out-of-stock products
      }) as Array<ApiResult>;

      resultsFromMetafield = resultsFromMetafield.slice(0, limits);

      const attachLocalPrices = async (products: Array<ApiResult | SearchProduct>) => {
        if (env.MULTICURRENCY_FEATURE && products.length > 0) {
          const productsPricing = await GetProductsPricing({
            first: products.length,
            query: products.map((p) => `id:${p.uid}`).join(' OR '),
            country: getCustomerCountry(),
          });

          products.forEach((product) => {
            const productEdges = productsPricing.data?.products.edges || [];
            const productPrice = productEdges.find((p) => p.node.id.split('/').pop() === product.uid);
            if (!productPrice) return;
            product.price = productPrice.node.priceRange.maxVariantPrice.amount;
            product.currency = productPrice.node.priceRange.maxVariantPrice.currencyCode;
            product.variant_compare_at_price = productPrice.node.compareAtPriceRange.maxVariantPrice.amount;
          });
        }
      }

      if (resultsFromMetafield.length === limits) {
        attachLocalPrices(resultsFromMetafield);
        return resultsFromMetafield;
      }

      // If the items from the "Style With" metafield is less than the limit,
      // fill in the missing products recommendations using Search Spring.
      // Make sure that the final result does not contain duplicates.

      const resultFromFallback = await personalizedRecommendations({
        tags: getRecommendationProfileFromTags(product.tags),
        shopper: '',
        cart: '',
        lastViewed: '',
        limits: 100,
        productId: productIdentifier,
      });

      let resultsFromFallback = recommendationProductsToSearchProducts(resultFromFallback.data[0].results);
      resultsFromFallback = shuffle(resultsFromFallback);
      resultsFromFallback = resultsFromFallback
        .filter((product) => !resultsFromMetafield.some((reco) => reco.handle === product.handle))
        .slice(0, limits - resultsFromMetafield.length);

      let resultsCombined = [...resultsFromMetafield, ...resultsFromFallback];
      attachLocalPrices(resultsCombined);
      return resultsCombined;
    },
  });

  React.useEffect(() => {
    styleWithRecommendations && !isLoading && setReady(true);
  }, [styleWithRecommendations, isLoading]);

  if (!isReady || product.tags.includes('category_self-love')) {
    return null;
  }

  if (!styleWithRecommendations || styleWithRecommendations.length === 0) {
    return (
      <ProductDetailsCarousel
        title="Style With"
        tags={getRecommendationProfileFromTags(product.tags)}
        className={className}
        classNames={classNames}
        controls={controls}
        draggable={draggable}
        limits={limits}
        product={product}
      />
    );
  }

  return (
    <div className={className}>
      <div className="text-[14px] font-bold uppercase mb-4">Style With</div>
      <div className="-mx-4 md:mx-0">
        <ProductCardCarousel
          withControls={controls}
          draggable={draggable}
          classNames={{
            ...classNames,
            viewport: cn('px-4 md:px-0', classNames?.viewport),
            slide: cn('w-[46%] md:w-[18%]', classNames?.slide),
          }}
          carousel={{
            tag: '',
            placement: 'product-page',
            products: styleWithRecommendations,
          }}
          height={315}
          width={210}
          layout="product"
        />
      </div>
    </div>
  );
};

export default ProductDetailsStyleWithCarousel;

function getRecommendationProfileFromTags(tags: string[]): string {
  if (tags.includes('category_dresses')) {
    return 'pdp-style-with-dresses';
  } else if (tags.includes('category_playsuits')) {
    return 'pdp-style-with-playsuits';
  } else if (tags.includes('category_swim-tops')) {
    return 'pdp-style-with-swim-tops';
  } else if (tags.includes('category_tops')) {
    return 'pdp-style-with-tops';
  } else if (tags.includes('category_swim-bottoms')) {
    return 'pdp-style-with-swim-bottoms';
  } else if (tags.includes('category_bottoms')) {
    return 'pdp-style-with-bottoms';
  } else if (tags.includes('category_shoes')) {
    return 'pdp-style-with-shoes';
  } else if (tags.includes('category_swim-accessories')) {
    return 'pdp-style-with-swim-accessories';
  } else {
    return 'cross-sell';
  }
}
