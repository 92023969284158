import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    backgroundColor: theme.colors.brand[4],
    textTransform: 'uppercase',
    fontSize: '1.1rem',
  },
}));

export default useStyles;
