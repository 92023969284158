import React from 'react';
import { authSelectors } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import { ActionIcon, WishlistIcon } from '@ui/components/core';
import { AnimatedIcon, FilledIcon } from '@ui/components/styles/Icon';
import { AppContext } from '@ui/context/context';
import { isWishlistFrontEnd } from '@ui/helpers/isWishlistFrontEnd';
import cn from '@ui/utils/cn';
import { ServerProductProps } from '@ui/nextServer';

type WishlistButtonProps = {
  className?: string;
  product: ServerProductProps['props']['product'];
  variant: ServerProductProps['props']['product']['variants'][number];
};

const WishlistButton = ({ className, product, variant }: WishlistButtonProps): React.ReactElement => {
  const { auth } = authSelectors;
  const { wishlistStore } = wishlistSelectors;
  const customerId = auth.use.customerId();
  const wishlist = wishlistStore.use.wishlist();
  const addWishlistProduct = wishlistStore.use.addWishlistProduct();
  const removeWishlistProduct = wishlistStore.use.removeWishlistProduct();
  const { state } = React.useContext(AppContext);
  const [wishlistItem, setWishlistItem] = React.useState<boolean>(false);
  const [wishlistAnimate, setwishlistAnimate] = React.useState<boolean>(false);

  const { themeColor } = FilledIcon({ filled: wishlistItem, animate: wishlistAnimate }).classes;
  const { flutter } = AnimatedIcon({ animate: wishlistAnimate }).classes;
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    if (isMounted.current) return;
    if (!isWishlistFrontEnd(wishlist)) return;
    if (!wishlist.products[product.handle]) return;
    setWishlistItem(true);
    isMounted.current = true;
  }, [wishlist, product.handle]);

  const addToWishList = (): void => {
    if (!product || !state.ssViewedProduct) return;

    addWishlistProduct({
      customerId,
      productId: product.id.split('/', -1).pop() as string,
      variantId: variant.id.split('/', -1).pop() as string,
    });

    setWishlistItem(true);
    setwishlistAnimate(!wishlistAnimate);
  };

  const removeFromWishlist = (): void => {
    if (!product || !state.ssViewedProduct) return;

    removeWishlistProduct({
      customerId,
      productId: product.id.split('/', -1).pop() as string,
    });

    setWishlistItem(false);
    setwishlistAnimate(false);
  };

  return (
    <ActionIcon
      variant="transparent"
      className={cn('isolate size-6 min-h-0 min-w-0', className)}
      onClick={() => {
        if (!wishlistItem) {
          addToWishList();
        } else {
          removeFromWishlist();
        }
      }}
    >
      <WishlistIcon height={10} width={10} viewBox="0 0 25 25" className={flutter} />
      <WishlistIcon height={12} width={12} viewBox="0 0 25 25" className={flutter} />
      <WishlistIcon height={14} width={14} viewBox="0 0 25 25" className={flutter} />
      <WishlistIcon height={16} width={16} viewBox="0 0 25 25" className={flutter} />
      <WishlistIcon height={28} width={28} className={themeColor} />
    </ActionIcon>
  );
};

export default WishlistButton;
