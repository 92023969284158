import React from 'react';
import { Carousel, LeftChevronIcon, RightChevronIcon, ZoomIcon } from '@ui/components/core';
import { ActionIcon } from '@mantine/core';
import CarouselIndicators from '../CarouselIndicators/CarouselIndicators';
import { ServerProductProps } from '@ui/nextServer';

type ProductImageCarouselProps = {
  images: ServerProductProps['props']['product']['images'];
  onItemClick?: (image: any) => void;
};

const ProductImageCarousel = ({ images, onItemClick }: ProductImageCarouselProps): React.ReactElement => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  return (
    <div>
      <Carousel
        withControls={true}
        onSlideChange={setActiveSlide}
        nextControlIcon={<RightChevronIcon height={24} width={24} />}
        previousControlIcon={<LeftChevronIcon height={24} width={24} />}
        align="center"
        classNames={{
          root: 'w-full',
          controls: 'px-4 md:hidden [&>button]:size-6 [&>button]:bg-transparent',
          indicators: '-bottom-6 gap-2.5',
          slide: 'relative basis-auto w-full',
        }}
      >
        {images?.map((image, i) => (
          <Carousel.Slide key={image.id}>
            <div className="relative pb-[150%] isolate" onClick={() => onItemClick?.(image)}>
              <picture>
                <img
                  className="absolute w-full h-full object-cover"
                  src={image.url ?? ('' as string)}
                  alt={image.altText ?? ('' as string)}
                  width={320}
                  height={480}
                  loading={i > 1 ? 'lazy' : 'eager'}
                />
              </picture>
            </div>
          </Carousel.Slide>
        ))}
      </Carousel>
      <ActionIcon
        onClick={() => onItemClick?.(images[activeSlide])}
        className="absolute top-4 right-4 z-10 active:bg-transparent"
        size={24}
      >
        <ZoomIcon width={20} height={20} />
      </ActionIcon>
      <CarouselIndicators
        className="absolute -bottom-6 md:opacity-0"
        activeSlide={activeSlide}
        totalSlides={images?.length || 0}
      />
    </div>
  );
};

ProductImageCarousel.defaultProps = {
  onItemClick: undefined,
};

export default ProductImageCarousel;
