import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useProductScarabQueue({ productId }: { productId: string }) {
  const router = useRouter();
  useEffect(() => {
    window.ScarabQueue = window.ScarabQueue || [];
    window.ScarabQueue.push(['view', `g/${productId.split('/').pop()}`]);
  }, [router.asPath]);
}
