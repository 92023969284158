import React from 'react';
import { ProductCardCarousel } from '../../../shared';

type SectionProps = {
  products: any[];
};

const ProductDetailsRecentlyViewedSection = ({ products }: SectionProps) => {
  if (!products.length) {
    return null;
  }

  return (
    <div>
      <div className="text-[14px] font-bold uppercase mb-4">RECENTLY VIEWED</div>
      <div className="-mx-4 md:mx-0">
        <ProductCardCarousel
          carousel={{
            tag: '',
            placement: 'product-page',
            products,
          }}
          classNames={{
            viewport: 'px-4 md:px-0',
            slide: 'w-[46%] md:w-[18%]',
          }}
          height={315}
          width={210}
          withControls
          layout="product"
        />
      </div>
    </div>
  );
};

export default ProductDetailsRecentlyViewedSection;
