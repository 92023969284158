import React from 'react';

const BagHeartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M52.7 64.65H13.4C4.7 64.65 1 59.2 1 51.24L5.49 28.71C7.89 21.09 12.61 14.24 21.31 14.24H44.79C53.49 14.24 57.79 20.5 60.61 28.71L65.21 50.9C65.21 58.86 61.4 64.65 52.7 64.65Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M21.3099 21.4C21.3099 21.4 19.1899 1 33.1099 1C47.0299 1 44.7899 21.4 44.7899 21.4"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M21.31 28.33C23.4087 28.33 25.11 26.7719 25.11 24.85C25.11 22.928 23.4087 21.37 21.31 21.37C19.2113 21.37 17.51 22.928 17.51 24.85C17.51 26.7719 19.2113 28.33 21.31 28.33Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M44.9199 28.33C47.0186 28.33 48.7199 26.7719 48.7199 24.85C48.7199 22.928 47.0186 21.37 44.9199 21.37C42.8212 21.37 41.1199 22.928 41.1199 24.85C41.1199 26.7719 42.8212 28.33 44.9199 28.33Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M44 41.0802C43.9946 40.2763 43.8306 39.4814 43.5175 38.7408C43.2043 38.0002 42.7481 37.3284 42.1749 36.7638C41.6017 36.1992 40.9227 35.7529 40.1767 35.4503C39.4307 35.1477 38.6323 34.9947 37.8271 35.0002C35.83 35.0002 34.6132 37.0608 33.5016 38.1419C32.3868 37.0608 31.1286 35.0002 29.1793 35.0002C28.3735 34.9939 27.5745 35.1462 26.8277 35.4485C26.081 35.7507 25.4013 36.1969 24.8274 36.7615C24.2535 37.3262 23.7967 37.9983 23.4831 38.7393C23.1696 39.4803 23.0054 40.2758 23 41.0802C23 45.8977 33.5111 52 33.5111 52C33.5111 52 44 45.9709 44 41.0802Z"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default BagHeartIcon;
