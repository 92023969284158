import React from 'react';
import env from '@ui/env';

type ProductTolstoyModuleProps = {
  productId: string;
  tags?: string[];
};

const createMarkup = ({ productId, tags }: ProductTolstoyModuleProps) => ({
  __html: `<tolstoy-stories
        data-tags=${tags ? tags.join(',') : ''}
        class="tolstoy-stories"
        data-publish-id=${env.NEXT_PUBLIC_TOLSTOY_PDP_ID}
        data-product-id=${productId}
      ></tolstoy-stories>`,
});

const ProductTolstoyModule = ({ productId, tags }: ProductTolstoyModuleProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const formattedId = productId.split('/').reverse()[0];

  React.useEffect(() => {
    const script = document.createElement('script');
    const noModuleScript = document.createElement('script');
    if (ref && ref.current) {
      script.src = 'https://widget.gotolstoy.com/we/widget.js';
      script.setAttribute('type', 'module');
      script.setAttribute('async', 'true');
      script.setAttribute('data-shop', env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN);
      script.setAttribute('data-app-key', env.NEXT_PUBLIC_TOLSTOY_APP_KEY || '');

      noModuleScript.src = 'https://widget.gotolstoy.com/widget/widget.js';
      script.setAttribute('noModule', 'true');
      script.setAttribute('async', 'true');
      script.setAttribute('data-shop', env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN);
      script.setAttribute('data-app-key', env.NEXT_PUBLIC_TOLSTOY_APP_KEY || '');

      ref.current.appendChild(script);
      ref.current.appendChild(noModuleScript);
    }
  }, []);

  return (
    <>
      {!!(env.SHOW_TOLSTOY && env.NEXT_PUBLIC_TOLSTOY_APP_KEY) && (
        <div ref={ref}>
          <div dangerouslySetInnerHTML={createMarkup({ productId: formattedId, tags })} />
        </div>
      )}
    </>
  );
};

export default ProductTolstoyModule;
