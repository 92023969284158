import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { FacebookIcon, FacebookShareButton, PinterestIcon, PinterestShareButton } from 'react-share';
import { useRouter } from 'next/router';
import { notifications } from '@mantine/notifications';
import { Box, Group, Modal, ShareIcon, Stack, TextInput } from '../../../core';
import { Button } from '@ui/components/shared';
import { Text } from '../../typography';
import { useValidProducts } from '@ui/store/recentlyViewedStore';

const ShareButton = (): React.ReactElement => {
  const recentlyViewed = useValidProducts();

  const router = useRouter();

  const [shareUrl, setShareUrl] = React.useState('');

  React.useEffect(() => {
    setShareUrl(window.location.href);
  }, [router.query.product]);

  const [shareModalOpened, { toggle: shareModalOpen, close: shareModalClose }] = useDisclosure(false);

  const copy = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  const body = (
    <Stack align="center" spacing="1.5rem">
      <Text fz="13px" fw="700" ta="center" tt="uppercase">
        Share this item
      </Text>
      <Group w="100%">
        <TextInput
          w="100%"
          size="lg"
          value={shareUrl}
          contentEditable={false}
          className="group"
          rightSection={
            <Button
              variant="outline"
              className="bg-black text-white h-full w-full rounded-[2.08px] group-hover:inline-flex group-hover:text-black"
              onClick={() => {
                notifications.show({
                  message: 'Link copied!',
                  autoClose: 3000,
                  withCloseButton: true,
                  variant: 'success',
                });

                copy();
              }}
            >
              Copy
            </Button>
          }
          rightSectionWidth="27%"
        />
      </Group>
      <Stack w="100%">
        <FacebookShareButton url={shareUrl} style={{ width: '100%' }}>
          <Button
            variant="outline"
            leftIcon={<FacebookIcon size={32} round />}
            className="w-full md:text-[13px] h-12 font-normal gap-x-[0.625rem]"
          >
            Share via Facebook
          </Button>
        </FacebookShareButton>
        {recentlyViewed?.filter((product) => product.handle === (router.query.product as string))[0] &&
          recentlyViewed?.filter((product) => product.handle === (router.query.product as string))[0].imageUrl && (
            <PinterestShareButton
              media={
                recentlyViewed?.filter((product) => product.handle === (router.query.product as string))[0].imageUrl
              }
              url={shareUrl}
              style={{ width: '100%' }}
            >
              <Button
                variant="outline"
                className="w-full md:text-[13px] h-12 font-normal gap-x-[0.625rem]"
                leftIcon={<PinterestIcon size={32} round />}
              >
                Share via Pinterest
              </Button>
            </PinterestShareButton>
          )}
      </Stack>
    </Stack>
  );

  return (
    <>
      <Button
        variant="unstyled"
        className="md:text-[13px] font-bold gap-x-2 no-underline uppercase"
        leftIcon={<ShareIcon width={13} height={16} />}
        onClick={shareModalOpen}
      >
        <Box display={{ base: 'none', md: 'block' }}>Share</Box>
      </Button>
      <Modal
        opened={shareModalOpened}
        onClose={shareModalClose}
        headerProps={{ pb: 0 }}
        body={body}
        size="397px"
        withCloseButton
        withOverlay
        centered
      />
    </>
  );
};

export default ShareButton;
