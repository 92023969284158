import axios from 'axios';
import env from '@ui/env';

export type GetProductReviewsArgs = {
  productId: string;
  page?: number;
  perPage?: number;
  sort?: string;
  direction?: 'asc' | 'desc';
};

type CustomFieldBottomline = {
  title: string;
  average_score: number;
  score: number;
};

export type GetProductReviewsResponse = {
  response: {
    pagination: {
      page: number;
      per_page: number;
      total: number;
    };
    bottomline: {
      average_score: number;
      total_review: number;
      custom_fields_bottomline?: Record<string, CustomFieldBottomline>;
    };
    reviews: Array<ProductReview>;
  };
};

export type ProductReview = {
  id: number;
  verified_buyer: boolean;
  created_at: string;
  score: number;
  user: {
    display_name: string;
  };
  title: string;
  content: string;
  custom_fields?: Record<string, ProductReviewCustomField>;
  images_data?: Array<ProductReviewImage>;
  votes_up: number;
  votes_down: number;
};

export type ProductReviewCustomField = {
  title: string;
  value: string;
};

export type ProductReviewImage = {
  id: number;
  thumb_url: string;
  original_url: string;
};

export const getProductReviews = async ({ productId, page, perPage, sort, direction }: GetProductReviewsArgs) => {
  const { data } = await axios.request<GetProductReviewsResponse>({
    method: 'GET',
    url: `https://api-cdn.yotpo.com/v1/widget/${env.NEXT_PUBLIC_YOTPO_TMP_KEY}/products/${productId}/reviews.json`,
    headers: {
      Accept: 'application/json',
    },
    params: {
      page,
      per_page: perPage,
      sort,
      direction,
    },
  });

  return { data };
};
