import axios from 'axios';
import env from '@ui/env';

type CreateProductReviewArgs = {
  productId: string;
  productTitle: string;
  productUrl: string;
  reviewerName: string;
  reviewerEmail: string;
  reviewContent: string;
  reviewTitle: string;
  reviewScore: number;
  customFieldSize: string;
  customFieldHeight: string;
  customFieldFit: string;
};

const createProductReview = async ({
  productId,
  productTitle,
  productUrl,
  reviewerName,
  reviewerEmail,
  reviewContent,
  reviewTitle,
  reviewScore,
  customFieldSize,
  customFieldHeight,
  customFieldFit,
}: CreateProductReviewArgs) => {
  const { data } = await axios.request({
    method: 'POST',
    url: 'https://api.yotpo.com/v1/widget/reviews',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      appkey: env.NEXT_PUBLIC_YOTPO_APP_KEY,
      domain: env.NEXT_PUBLIC_BASE_URL,
      sku: productId,
      product_title: productTitle,
      product_url: productUrl,
      display_name: reviewerName,
      email: reviewerEmail,
      review_content: reviewContent,
      review_title: reviewTitle,
      review_score: reviewScore,
      custom_fields: {
        ['--' + env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_FIT_ID]: customFieldFit,
        ['--' + env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_SIZE_ID]: customFieldSize,
        ['--' + env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_HEIGHT_ID]: customFieldHeight,
      },
    },
  });

  return { data };
};

export default createProductReview;
