import React from 'react';
import { Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { Carousel, CloseIcon, LeftChevronIcon, RightChevronIcon } from '../../../core';
import { ActionIcon, Modal } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import cn from '@ui/utils/cn';
import GalleryImage from './GalleryImage';
import { ServerProductProps } from '@ui/nextServer';

type GalleryModalProps = {
  images: ServerProductProps['props']['product']['images'];
  opened: boolean;
  onClose: () => void;
};

const TRANSITION_DURATION = 300;

const GalleryModal = ({ images, opened, onClose }: GalleryModalProps): React.ReactElement => {
  const [embla, setEmbla] = React.useState<Embla | null>(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  const { ref: carouselRef, width: carouselWidth, height: carouselHeight } = useElementSize();
  const [initialScale, setInitialScale] = React.useState(-1);
  const [isPanEnabled, setIsPanEnabled] = React.useState(false);

  React.useEffect(() => {
    if (!carouselWidth || !carouselHeight) return;

    setInitialScale(carouselHeight / (carouselWidth * 1.5));
  }, [carouselWidth, carouselHeight]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      keepMounted={false}
      transitionProps={{ duration: TRANSITION_DURATION }}
      withCloseButton={false}
      fullScreen
      classNames={{
        content: 'h-full overflow-hidden',
        body: 'w-full h-full p-0 relative isolate',
      }}
    >
      <div className="p-4 md:p-6 top-0 inset-x-0 w-full z-10 absolute flex justify-between">
        <div className="text-xs font-bold">
          {currentSlide + 1} / {images?.length}
        </div>
        <ActionIcon onClick={onClose} size="xs" className="touch-target focus:outline-none">
          <CloseIcon width={20} height={20} />
        </ActionIcon>
      </div>

      <Carousel
        ref={carouselRef}
        getEmblaApi={setEmbla}
        nextControlIcon={<RightChevronIcon height={20} width={20} />}
        previousControlIcon={<LeftChevronIcon height={20} width={20} />}
        onSlideChange={setCurrentSlide}
        classNames={{
          root: 'h-full',
          viewport: 'h-full',
          container: 'h-full',
          slide: 'basis-auto w-full',
          control: cn('bg-transparent size-6 touch-target', isPanEnabled && 'pointer-events-none'),
          controls: cn(
            'transition max-sm:px-3 md:w-[calc(100vh/1.5+106px)] md:left-1/2 md:-translate-x-1/2',
            isPanEnabled && 'opacity-0',
          ),
        }}
      >
        {images?.map((image) => (
          <Carousel.Slide key={image.id} onClick={onClose}>
            <GalleryImage
              initialScale={initialScale}
              imageAlt={image.altText || ''}
              imageUrl={image.url}
              onPanChanged={setIsPanEnabled}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Modal>
  );
};

export default GalleryModal;
