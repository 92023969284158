export type BreadcrumbLink = { label: string; href: string };

export const BreadcrumbMapping: Record<string, Array<BreadcrumbLink>> = {
  '/collections/new': [
    { label: 'New', href: '/collections/new' },
    { label: 'All New', href: '/collections/new' },
  ],
  '/collections/new/category_dresses': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Dresses', href: '/collections/new/category_dresses' },
  ],
  '/collections/new/category_playsuits': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Playsuits', href: '/collections/new/category_playsuits' },
  ],
  '/collections/new/category_tops': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Tops', href: '/collections/new/category_tops' },
  ],
  '/collections/new/category_bottoms': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Bottoms', href: '/collections/new/category_bottoms' },
  ],
  '/collections/new/category_swim': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Swim', href: '/collections/new/category_swim' },
  ],
  '/collections/new/category_shoes': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Shoes', href: '/collections/new/category_shoes' },
  ],
  '/collections/new/category_accessories': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Accessories', href: '/collections/new/category_accessories' },
  ],
  '/collections/new/category_co-ords': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Sets & Co-Ords', href: '/collections/new/category_co-ords' },
  ],
  '/collections/new/category_exclusive': [
    { label: 'New', href: '/collections/new' },
    { label: 'New Exclusives', href: '/collections/new/category_exclusive' },
  ],
  '/collections/clothing': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'All Clothing', href: '/collections/clothing' },
  ],
  '/collections/dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
  ],
  '/collections/mini-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Mini Dresses', href: '/collections/mini-dress' },
  ],
  '/collections/mini-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Mini Dresses', href: '/collections/mini-dresses' },
  ],
  '/collections/midi-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Midi Dresses', href: '/collections/midi-dress' },
  ],
  '/collections/midi-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Midi Dresses', href: '/collections/midi-dresses' },
  ],
  '/collections/maxi-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Maxi Dresses', href: '/collections/maxi-dress' },
  ],
  '/collections/maxi-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Maxi Dresses', href: '/collections/maxi-dresses' },
  ],
  '/collections/slip-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Slip Dresses', href: '/collections/slip-dresses' },
  ],
  '/collections/sequin-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Sequin Dresses', href: '/collections/sequin-dress' },
  ],
  '/collections/bodycon-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Bodycon Dresses', href: '/collections/bodycon-dress' },
  ],
  '/collections/printed-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Printed Dresses', href: '/collections/printed-dress' },
  ],
  '/collections/printed-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Printed Dresses', href: '/collections/printed-dresses' },
  ],
  '/collections/backless-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Backless Dresses', href: '/collections/backless-dress' },
  ],
  '/collections/off-the-shoulder-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Off The Shoulder Dresses', href: '/collections/off-the-shoulder-dress' },
  ],
  '/collections/long-sleeve-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Long Sleeve Dresses', href: '/collections/long-sleeve-dress' },
  ],
  '/collections/lace-dress': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Lace Dresses', href: '/collections/lace-dress' },
  ],
  '/collections/party-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Party Dresses', href: '/collections/party-dresses' },
  ],
  '/collections/dresses/occasion_bridesmaid-&-wedding-guest': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Bridesmaid & Wedding Guest', href: '/collections/dresses/occasion_bridesmaid-&-wedding-guest' },
  ],
  '/collections/dresses/occasion_formal-&-cocktail': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Formal & Cocktail', href: '/collections/dresses/occasion_formal-&-cocktail' },
  ],
  '/collections/dresses/occasion_beach-&-holiday': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Beach & Holiday', href: '/collections/dresses/occasion_beach-&-holiday' },
  ],
  '/collections/dresses/occasion_brunch': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Brunch', href: '/collections/dresses/occasion_brunch' },
  ],
  '/collections/dresses/occasion_date-night': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Date Night', href: '/collections/dresses/occasion_date-night' },
  ],
  '/collections/day-dresses': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Day Dresses', href: '/collections/day-dresses' },
  ],
  '/collections/dresses/occasion_festival': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Festival', href: '/collections/dresses/occasion_festival' },
  ],
  '/collections/dresses/occasion_racing': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Racing', href: '/collections/dresses/occasion_racing' },
  ],
  '/collections/dresses/occasion_work': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Dresses', href: '/collections/dresses' },
    { label: 'Work', href: '/collections/dresses/occasion_work' },
  ],
  '/collections/playsuits': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Playsuits', href: '/collections/playsuits' },
  ],
  '/collections/party-playsuits': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Playsuits', href: '/collections/playsuits' },
    { label: 'Party Playsuits', href: '/collections/party-playsuits' },
  ],
  '/collections/printed-playsuits': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Playsuits', href: '/collections/playsuits' },
    { label: 'Printed Playsuits', href: '/collections/printed-playsuits' },
  ],
  '/collections/jumpsuits': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Playsuits', href: '/collections/playsuits' },
    { label: 'Jumpsuits', href: '/collections/jumpsuits' },
  ],
  '/collections/tops': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
  ],
  '/collections/crop-tops': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Crop Tops', href: '/collections/crop-tops' },
  ],
  '/collections/sleeveless-tops': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Sleeveless Tops', href: '/collections/sleeveless-tops' },
  ],
  '/collections/short-sleeved-tops': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Short Sleeved Tops', href: '/collections/short-sleeved-tops' },
  ],
  '/collections/long-sleeved-tops': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Long Sleeved Tops', href: '/collections/long-sleeved-tops' },
  ],
  '/collections/basics': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Basics', href: '/collections/basics' },
  ],
  '/collections/bodysuits-bralets': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Bodysuits & Bralets', href: '/collections/bodysuits-bralets' },
  ],
  '/collections/blazers': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Blazers', href: '/collections/blazers' },
  ],
  '/collections/knits': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Knits', href: '/collections/knits' },
  ],
  '/collections/sets': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Sets & Co-Ords', href: '/collections/sets' },
  ],
  '/collections/outerwear': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Tops', href: '/collections/tops' },
    { label: 'Outerwear', href: '/collections/outerwear' },
  ],
  '/collections/bottoms': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
  ],
  '/collections/skirts': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Skirts', href: '/collections/skirts' },
  ],
  '/collections/skirts/length_mini': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Mini Skirts', href: '/collections/skirts/length_mini' },
  ],
  '/collections/skirts/length_midi': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Midi Skirts', href: '/collections/skirts/length_midi' },
  ],
  '/collections/skirts/length_maxi': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Maxi Skirts', href: '/collections/skirts/length_maxi' },
  ],
  '/collections/skirts/color_denim': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Denim Skirts', href: '/collections/skirts/color_denim' },
  ],
  '/collections/shorts': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Shorts', href: '/collections/shorts' },
  ],
  '/collections/shorts/color_denim': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Denim Shorts', href: '/collections/shorts/color_denim' },
  ],
  '/collections/fashion-shorts': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Fashion Shorts', href: '/collections/fashion-shorts' },
  ],
  '/collections/pants': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Pants', href: '/collections/pants' },
  ],
  '/collections/leggings': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Leggings', href: '/collections/leggings' },
  ],
  '/collections/skinny-pants': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Skinny Pants', href: '/collections/skinny-pants' },
  ],
  '/collections/tailored-pants': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Tailored Pants', href: '/collections/tailored-pants' },
  ],
  '/collections/wide-leg-pants': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Wide Leg Pants', href: '/collections/wide-leg-pants' },
  ],
  '/collections/jeans': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Bottoms', href: '/collections/bottoms' },
    { label: 'Jeans', href: '/collections/jeans' },
  ],
  '/collections/swim': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Swim', href: '/collections/swim' },
  ],
  '/collections/swim-tops': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Swim', href: '/collections/swim' },
    { label: 'Swim Tops', href: '/collections/swim-tops' },
  ],
  '/collections/swim-bottoms': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Swim', href: '/collections/swim' },
    { label: 'Swim Bottoms', href: '/collections/swim-bottoms' },
  ],
  '/collections/cover-ups': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Swim', href: '/collections/swim' },
    { label: 'Cover Ups', href: '/collections/cover-ups' },
  ],
  '/collections/swim-accessories': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Swim', href: '/collections/swim' },
    { label: 'Swim Accessories', href: '/collections/swim-accessories' },
  ],
  '/collections/back-in-stock': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'All Back in Stock', href: '/collections/back-in-stock' },
  ],
  '/collections/back-in-stock/category_dresses': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'Back in Dresses', href: '/collections/back-in-stock/category_dresses' },
  ],
  '/collections/back-in-stock/category_playsuits': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'Back in Playsuits', href: '/collections/back-in-stock/category_playsuits' },
  ],
  '/collections/back-in-stock/category_tops': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'Back in Tops', href: '/collections/back-in-stock/category_tops' },
  ],
  '/collections/back-in-stock/category_bottoms': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'Back in Bottoms', href: '/collections/back-in-stock/category_bottoms' },
  ],
  '/collections/back-in-stock/category_accessories': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'Back in Accessories', href: '/collections/back-in-stock/category_accessories' },
  ],
  '/collections/back-in-stock/category_swim': [
    { label: 'Back in Stock', href: '/collections/back-in-stock' },
    { label: 'Back in Swim', href: '/collections/back-in-stock/category_swim' },
  ],
  '/collections/shoes': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'All Shoes', href: '/collections/shoes' },
  ],
  '/collections/heels': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Heels', href: '/collections/heels' },
  ],
  '/collections/mules': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Mules', href: '/collections/mules' },
  ],
  '/collections/slides': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Slides', href: '/collections/slides' },
  ],
  '/collections/boots': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Boots', href: '/collections/boots' },
  ],
  '/collections/sandals': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Sandals', href: '/collections/sandals' },
  ],
  '/collections/flats': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Flats', href: '/collections/flats' },
  ],
  '/collections/sneakers': [
    { label: 'Shoes', href: '/collections/shoes' },
    { label: 'Sneakers', href: '/collections/sneakers' },
  ],
  '/collections/accessories': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'All Accessories', href: '/collections/accessories' },
  ],
  '/collections/jewellery': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Jewellery', href: '/collections/jewellery' },
  ],
  '/collections/necklaces': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Necklaces', href: '/collections/necklaces' },
  ],
  '/collections/earrings': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Earrings', href: '/collections/earrings' },
  ],
  '/collections/bracelets': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Bracelets', href: '/collections/bracelets' },
  ],
  '/collections/rings': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Rings', href: '/collections/rings' },
  ],
  '/collections/self-love': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Self Love', href: '/collections/self-love' },
  ],
  '/collections/accessories/category_magic-bra': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Breast Styling Essentials', href: '/collections/accessories/category_magic-bra' },
  ],
  '/collections/beauty': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Beauty & Wellness', href: '/collections/beauty' },
  ],
  '/collections/bags': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Bags', href: '/collections/bags' },
  ],
  '/collections/sunglasses': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Sunglasses', href: '/collections/sunglasses' },
  ],
  '/collections/headwear': [
    { label: 'Accessories', href: '/collections/accessories' },
    { label: 'Hair & Headwear', href: '/collections/headwear' },
  ],
  '/collections/one-pieces': [
    { label: 'Clothing', href: '/collections/clothing' },
    { label: 'Swim', href: '/collections/swim' },
    { label: 'One-Pieces', href: '/collections/one-pieces' },
  ],
  '/collections/exclusives': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'All Exclusives', href: '/collections/exclusives' },
  ],
  '/collections/exclusive/brand_hello-molly-exclusive': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Hello Molly', href: '/collections/exclusive/brand_hello-molly-exclusive' },
  ],
  '/collections/exclusive/brand_dear-emilia': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Dear Emilia', href: '/collections/exclusive/brand_dear-emilia' },
  ],
  '/collections/exclusive/brand_sur-belle': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Sur Belle', href: '/collections/exclusive/brand_sur-belle' },
  ],
  '/collections/swim/brand_hello-molly-exclusive': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Hello Molly Swim', href: '/collections/swim/brand_hello-molly-exclusive' },
  ],
  '/collections/accessories/brand_undercover-style-helpers': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Undercover Style Helpers', href: '/collections/accessories/brand_undercover-style-helpers' },
  ],
  '/collections/exclusive/brand_hello-mini': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Hello Mini', href: '/collections/exclusive/brand_hello-mini' },
  ],
  '/collections/wedding-parlour': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Wedding Parlour', href: '/collections/wedding-parlour' },
  ],
  '/collections/hello-molly-basics': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'BASE by Hello Molly', href: '/collections/hello-molly-basics' },
  ],
  '/collections/hello-molly/trend_denim': [
    { label: 'Exclusives', href: '/collections/exclusives' },
    { label: 'Hello Molly DNM', href: '/collections/hello-molly/trend_denim' },
  ],
  '/collections/sale': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'All Sale', href: '/collections/sale' },
  ],
  '/collections/sale/category_new': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'New Added To Sale', href: '/collections/sale/category_new' },
  ],
  '/collections/sale/category_dresses': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Dresses', href: '/collections/sale/category_dresses' },
  ],
  '/collections/sale/category_playsuits': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Playsuits', href: '/collections/sale/category_playsuits' },
  ],
  '/collections/sale/category_tops': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Tops', href: '/collections/sale/category_tops' },
  ],
  '/collections/sale/category_bottoms': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Bottoms', href: '/collections/sale/category_bottoms' },
  ],
  '/collections/sale/category_shoes': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Bottoms', href: '/collections/sale/category_shoes' },
  ],
  '/collections/sale/category_accessories': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Accessories', href: '/collections/sale/category_accessories' },
  ],
  '/collections/sale/category_swim': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Sale Swim', href: '/collections/sale/category_swim' },
  ],
  '/collections/sale/category_final-sale': [
    { label: 'Sale', href: '/collections/sale' },
    { label: 'Final Sale', href: '/collections/sale/category_final-sale' },
  ],
  '/collections/summer-shop': [
    { label: 'Vacay Edit', href: '/collections/summer-shop' },
    { label: 'All Vacay Edit', href: '/collections/summer-shop' },
  ],
};
