import axios from 'axios';
import { ProductReview } from './getProductReviews';
import env from '@ui/env';

export type ProductFilters = {
  rating?: string;
  height?: string;
  size?: string;
  media?: boolean;
};

export type FilterProductReviewsResponse = {
  response: {
    pagination: {
      page: number;
      per_page: number;
      total: number;
    };
    reviews: Array<ProductReview>;
  };
};

export const getFilteredProductReviews = async ({
  productId,
  page,
  perPage,
  sort,
  direction,
  filters,
}: {
  productId: string;
  page?: number;
  perPage?: number;
  sort?: string;
  direction?: 'asc' | 'desc';
  filters: ProductFilters;
}) => {
  const { data } = await axios.request<FilterProductReviewsResponse>({
    method: 'POST',
    url: `https://api-cdn.yotpo.com/v1/reviews/${env.NEXT_PUBLIC_YOTPO_TMP_KEY}/filter.json`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      page,
      per_page: perPage,
      domain_key: productId,
      sortings: sort ? [{ sort_by: sort, ascending: direction === 'asc' }] : [],
      pictured: filters.media || undefined,
      scores: filters.rating ? [filters.rating] : [],
      crfs: [
        {
          question_id: env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_HEIGHT_ID,
          answers: filters.height ? HeightFilterMappings[filters.height] || [filters.height] : [],
        },
        {
          question_id: env.NEXT_PUBLIC_YOTPO_CUSTOM_FIELD_SIZE_ID,
          answers: filters.size ? SizeFilterMappings[filters.size] || [filters.size] : [],
        },
      ],
    },
  });

  return { data };
};

// The "height" field in the review form is a free-text input which makes filtering a little challenging.
// To make things worse, Yotpo API only performs an exact match when filtering custom field properties.
// As a workaround, we will attempt to generate all the possible height variations for each measurement.

export const HeightFilterMappings: Record<string, string[]> = {
  '152 CM (5’) OR SHORTER': [
    ...getHeightVariationsForCm('150'),
    ...getHeightVariationsForCm('151'),
    ...getHeightVariationsForCm('152'),
    ...getHeightVariationsForFt('5'),
    ...getHeightVariationsForIn('5', '0'),
    '152 CM (5’) OR SHORTER',
  ],
  '153-155 CM (5’1”)': [
    ...getHeightVariationsForCm('153'),
    ...getHeightVariationsForCm('154'),
    ...getHeightVariationsForCm('155'),
    ...getHeightVariationsForIn('5', '1'),
    '153-155 CM (5’1”)',
  ],
  '156-157 CM (5’2”)': [
    ...getHeightVariationsForCm('156'),
    ...getHeightVariationsForCm('157'),
    ...getHeightVariationsForIn('5', '2'),
    '156-157 CM (5’2”)',
  ],
  '158-160 CM (5’3”)': [
    ...getHeightVariationsForCm('158'),
    ...getHeightVariationsForCm('159'),
    ...getHeightVariationsForCm('160'),
    ...getHeightVariationsForIn('5', '3'),
    '158-160 CM (5’3”)',
  ],
  '161-162 CM (5’4”)': [
    ...getHeightVariationsForCm('161'),
    ...getHeightVariationsForCm('162'),
    ...getHeightVariationsForIn('5', '4'),
    '161-162 CM (5’4”)',
  ],
  '163-165 CM (5’5”)': [
    ...getHeightVariationsForCm('163'),
    ...getHeightVariationsForCm('164'),
    ...getHeightVariationsForCm('165'),
    ...getHeightVariationsForIn('5', '5'),
    '163-165 CM (5’5”)',
  ],
  '166-167 CM (5’6”)': [
    ...getHeightVariationsForCm('166'),
    ...getHeightVariationsForCm('167'),
    ...getHeightVariationsForIn('5', '6'),
    '166-167 CM (5’6”)',
  ],
  '168-170 CM (5’7”)': [
    ...getHeightVariationsForCm('168'),
    ...getHeightVariationsForCm('169'),
    ...getHeightVariationsForCm('170'),
    ...getHeightVariationsForIn('5', '7'),
    '168-170 CM (5’7”)',
  ],
  '171-172 CM (5’8”)': [
    ...getHeightVariationsForCm('171'),
    ...getHeightVariationsForCm('172'),
    ...getHeightVariationsForIn('5', '8'),
    '171-172 CM (5’8”)',
  ],
  '172-175 CM (5’9”) OR TALLER': [
    ...getHeightVariationsForCm('172'),
    ...getHeightVariationsForCm('173'),
    ...getHeightVariationsForCm('174'),
    ...getHeightVariationsForCm('175'),
    ...getHeightVariationsForCm('176'),
    ...getHeightVariationsForCm('177'),
    ...getHeightVariationsForCm('178'),
    ...getHeightVariationsForCm('179'),
    ...getHeightVariationsForCm('180'),
    ...getHeightVariationsForCm('181'),
    ...getHeightVariationsForCm('182'),
    ...getHeightVariationsForCm('183'),
    ...getHeightVariationsForCm('184'),
    ...getHeightVariationsForCm('185'),
    ...getHeightVariationsForCm('186'),
    ...getHeightVariationsForCm('187'),
    ...getHeightVariationsForCm('188'),
    ...getHeightVariationsForCm('189'),
    ...getHeightVariationsForCm('190'),
    ...getHeightVariationsForIn('5', '9'),
    ...getHeightVariationsForIn('5', '10'),
    ...getHeightVariationsForIn('5', '11'),
    ...getHeightVariationsForIn('6', '0'),
    ...getHeightVariationsForIn('6', '1'),
    ...getHeightVariationsForIn('6', '2'),
    ...getHeightVariationsForIn('6', '3'),
    ...getHeightVariationsForIn('6', '4'),
    ...getHeightVariationsForIn('6', '5'),
    ...getHeightVariationsForIn('6', '6'),
    ...getHeightVariationsForIn('6', '7'),
    ...getHeightVariationsForIn('6', '8'),
    ...getHeightVariationsForIn('6', '9'),
    ...getHeightVariationsForIn('6', '10'),
    ...getHeightVariationsForIn('6', '11'),
    '172-175 CM (5’9”) OR TALLER',
  ],
};

export const SizeFilterMappings: Record<string, string[]> = {
  XS: ['XS', 'Xs', 'xs'],
  S: ['S', 's', 'Sm', 'sm', 'Small', 'small'],
  M: ['M', 'm', 'Med', 'med', 'Medium', 'medium'],
  L: ['L', 'l', 'Lg', 'lg', 'Large', 'large'],
  XL: ['XL', 'Xl', 'xl'],
};

function getHeightVariationsForCm(cm: string) {
  return [cm, `${cm}cm`, `${cm} cm`, `${cm}CM`, `${cm} CM`];
}

function getHeightVariationsForFt(feet: string) {
  return [
    feet,
    `${feet}ft`,
    `${feet} ft`,
    `${feet}feet`,
    `${feet} feet`,
    `${feet}FT`,
    `${feet} FT`,
    `${feet}Feet`,
    `${feet} Feet`,
  ];
}

function getHeightVariationsForIn(feet: string, inches: string) {
  return [
    `${feet}’${inches}”`,
    `${feet}’ ${inches}”`,
    `${feet}”${inches}`,
    `${feet}”${inches}’`,
    `${feet}” ${inches}’`,
    `${feet}’${inches}`,
    `${feet}’ ${inches}`,
    `${feet}'${inches}`,
    `${feet}' ${inches}`,
    `${feet}'${inches}''`,
    `${feet}' ${inches}''`,
    `${feet},${inches}`,
    `${feet}, ${inches}`,
    `${feet}'${inches}"`,
    `${feet}' ${inches}"`,
    `${feet}ft ${inches}`,
    `${feet}feet ${inches}`,
    `${feet}/${inches}`,
    `${feet} / ${inches}`,
    `${feet}.${inches}"`,
  ];
}
