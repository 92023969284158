import React from 'react';

const ThumbUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.50949 6.88184H2.66979C1.65795 6.88184 0.830078 7.7097 0.830078 8.72154V15.1605C0.830078 16.1724 1.65795 17.0002 2.66979 17.0002H4.50949C5.52133 17.0002 6.3492 16.1724 6.3492 15.1605V8.72154C6.3492 7.7097 5.52133 6.88184 4.50949 6.88184ZM4.50949 15.1605H2.66979V8.72154H4.50949V15.1605Z"
      fill="black"
    />
    <path
      d="M16.0541 6.88089H14.4904V6.83489C14.8123 5.45511 15.4102 2.83353 14.3524 1.22379C13.9385 0.579887 13.2486 0.165953 12.4207 0.027975C11.9148 -0.0640104 11.3629 0.0739682 10.9489 0.395917C10.535 0.671873 10.305 1.08581 10.2131 1.54573C9.89112 3.61541 8.6953 6.00702 7.95942 7.4328C7.4535 8.39864 7.26953 8.76659 7.26953 9.13453V15.5735C7.26953 15.9414 7.40751 16.2634 7.63747 16.4934C8.14339 16.9993 8.87928 16.9993 9.47718 16.9993H9.52317H16.0081C17.8938 16.9993 19.1816 16.0334 19.1816 14.6537V10.0544C19.2276 8.53662 17.5719 6.88089 16.0541 6.88089ZM17.3879 14.6537C17.3879 14.8836 16.698 15.1596 16.0541 15.1596H9.47718C9.38519 15.1596 9.24722 15.1596 9.10924 15.1596V9.22651C9.20122 9.04254 9.3852 8.62861 9.56917 8.26067C10.351 6.74291 11.6388 4.16732 11.9608 1.91368C11.9608 1.86768 12.0528 1.86768 12.0988 1.86768C12.5127 1.95967 12.6967 2.09765 12.7887 2.28162C13.4326 3.20147 12.8806 5.45511 12.6507 6.42096L12.6047 6.69691C12.6047 6.7889 12.5587 6.88089 12.5127 6.97287C12.4207 7.20283 12.1907 7.70875 12.5127 8.21467C12.7427 8.53662 13.0646 8.72059 13.5705 8.72059H16.0541C16.5601 8.72059 17.3879 9.54846 17.3879 10.1004V14.6537Z"
      fill="black"
    />
  </svg>
);

export default ThumbUpIcon;
