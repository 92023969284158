import { HeightFilterMappings, SizeFilterMappings } from '@ui/axios/yotpo/getFilteredProductReviews';
import axios from 'axios';

export function normalizeSize(input: string) {
  const size = unescapeHtmlEntities(input);
  const match = Object.keys(SizeFilterMappings).find((key) => SizeFilterMappings[key].includes(size));
  return match || size;
}

export function normalizeHeight(input: string) {
  const height = unescapeHtmlEntities(input);
  const match = Object.keys(HeightFilterMappings).find((key) => HeightFilterMappings[key].includes(height));
  return match || height;
}

export function unescapeHtmlEntities(input: string) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = input;
  return textarea.value;
}

/**
 * NZ and GB stores do not have dedicated Yotpo accounts.
 * These stores are only making use of either the AU or US reviews.
 * For these stores, the product id must be fetched from the AU/US store.
 */
export async function getCanonicalProductId(product: { id: string; handle: string }) {
  const canonicalProductUrl = getProductReviewsCanonicalProductUrl();

  return canonicalProductUrl
    ? (await axios.get(`${canonicalProductUrl}/${product.handle}.json`)).data.product.id
    : product.id.split('/').pop();
}

function getProductReviewsCanonicalProductUrl(): string | undefined {
  if (process.env.NEXT_PUBLIC_REGION === 'NZ') {
    return 'https://content.hellomolly.com.au/products';
  } else if (process.env.NEXT_PUBLIC_REGION === 'GB') {
    return 'https://www.hellomolly.com/products';
  } else {
    return undefined;
  }
}
