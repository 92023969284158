import React from 'react';

const ShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.74447 16H9.27631C10.7949 16 12.0208 14.7741 12.0208 13.2555V8.20569C12.0208 6.88834 10.9596 5.82715 9.64223 5.82715H8.37978C8.06874 5.82715 7.83088 6.065 7.83088 6.37604C7.83088 6.68708 8.06874 6.92494 8.37978 6.92494H9.62394C10.3375 6.92494 10.9047 7.49213 10.9047 8.20569V13.2738C10.9047 14.1886 10.1728 14.9205 9.25801 14.9205H2.74447C1.82965 14.9205 1.09779 14.1886 1.09779 13.2738V8.20569C1.09779 7.49213 1.66498 6.92494 2.37854 6.92494H3.6227C3.93374 6.92494 4.17159 6.68708 4.17159 6.37604C4.17159 6.065 3.93374 5.82715 3.6227 5.82715H2.37854C1.06119 5.82715 0 6.88834 0 8.20569V13.2738C0 14.7741 1.22586 16 2.74447 16Z"
      fill="black"
    />
    <path
      d="M3.20174 3.59525L5.36072 1.74731V9.0293C5.36072 9.34034 5.59858 9.5782 5.90962 9.5782C6.22066 9.5782 6.45851 9.34034 6.45851 9.0293V1.74731L8.61749 3.59525C8.72727 3.68674 8.85535 3.72333 8.98342 3.72333C9.12979 3.72333 9.29446 3.65014 9.40424 3.54037C9.6055 3.30251 9.56891 2.95488 9.34935 2.77191L6.27555 0.137223C6.07428 -0.0457412 5.76324 -0.0457412 5.56198 0.137223L2.48818 2.77191C2.25032 2.97318 2.23203 3.32081 2.43329 3.54037C2.63455 3.75992 2.96389 3.79652 3.20174 3.59525Z"
      fill="black"
    />
  </svg>
);

export default ShareIcon;
