import React from 'react';

export default function VerifiedBadgeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="14" height="14" rx="7" fill="black" />
      <path
        d="M9.85482 4.89528C9.90133 4.94174 9.93822 4.99691 9.9634 5.05763C9.98857 5.11836 10.0015 5.18345 10.0015 5.24919C10.0015 5.31493 9.98857 5.38003 9.9634 5.44075C9.93822 5.50148 9.90133 5.55665 9.85482 5.60311L6.35318 9.10475C6.30672 9.15126 6.25155 9.18815 6.19082 9.21333C6.13009 9.2385 6.065 9.25146 5.99926 9.25146C5.93352 9.25146 5.86843 9.2385 5.8077 9.21333C5.74697 9.18815 5.6918 9.15126 5.64535 9.10475L4.14464 7.60405C4.05078 7.51018 3.99805 7.38287 3.99805 7.25013C3.99805 7.11739 4.05078 6.99008 4.14464 6.89621C4.23851 6.80235 4.36581 6.74962 4.49856 6.74962C4.6313 6.74962 4.75861 6.80235 4.85247 6.89621L5.99926 8.04363L9.14698 4.89528C9.19344 4.84877 9.24861 4.81187 9.30934 4.7867C9.37007 4.76152 9.43516 4.74857 9.5009 4.74857C9.56664 4.74857 9.63173 4.76152 9.69246 4.7867C9.75319 4.81187 9.80836 4.84877 9.85482 4.89528Z"
        fill="white"
      />
    </svg>
  );
}
