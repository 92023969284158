import React from 'react';

export default function ZoomIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6407_36689)">
        <path
          d="M5.5375 10.1766C7.83639 10.1766 9.7 8.31295 9.7 6.01406C9.7 3.71518 7.83639 1.85156 5.5375 1.85156C3.23862 1.85156 1.375 3.71518 1.375 6.01406C1.375 8.31295 3.23862 10.1766 5.5375 10.1766Z"
          stroke="black"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.84766 8.85938L12.54 12.5517"
          stroke="black"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5375 4.19531V7.81406M7.3375 6.01406H3.71875"
          stroke="black"
          strokeWidth="0.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6407_36689">
          <rect width="13" height="13" fill="white" transform="translate(0.5 0.476562)" />
        </clipPath>
      </defs>
    </svg>
  );
}
