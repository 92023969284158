import env from '@ui/env';
import axios from 'axios';

type TopMentionedTopicsResponse = {
  top_topics: {
    top_mention_topics: { name: string }[];
  };
};

export const getTopMentionedTopics = async ({ productId }: { productId: string }) => {
  const { data } = await axios.request<TopMentionedTopicsResponse>({
    method: 'POST',
    url: `https://api-cdn.yotpo.com/v1/topic/${env.NEXT_PUBLIC_YOTPO_TMP_KEY}/topics.json`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      domain_key: productId,
    },
  });

  return { data };
};
