import cn from '@ui/utils/cn';

export default function ProductFitChart({ value, className }: { value: number; className?: string }) {
  return (
    <div data-value={value} className={cn('relative', className)}>
      <div className="flex items-center w-full h-1.5 bg-[#FFEAF4] rounded-full relative">
        <DotScale left={0} />
        <DotScale left={0.25} />
        <DotScale left={0.5} />
        <DotScale left={0.75} />
        <DotScale left={1} />
        <DotScale left={(value - 1) / 2} checked />
      </div>
      <div className="flex items-center justify-between mt-2">
        <div className="text-[10px] tracking-[0.4px]">Too small</div>
        <div className="text-[10px] tracking-[0.4px]">True to size</div>
        <div className="text-[10px] tracking-[0.4px]">Too large</div>
      </div>
    </div>
  );
}

function DotScale({ left, checked }: { left: number; checked?: boolean }) {
  return (
    <div
      data-slot="dot"
      className="absolute size-1.5 -translate-x-1/2 rounded-full bg-[#FCC6E1] data-[status=on]:bg-black data-[status=on]:ring-2 data-[status=on]:ring-black"
      data-status={checked ? 'on' : 'off'}
      style={{ left: `${left * 100}%` }}
    />
  );
}
